import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTCreateAPIKeyModal = _resolveComponent("KTCreateAPIKeyModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Create API Key Modal Example",
      description: "Click on the below buttons to launch <br/>a new API Key creation example.",
      image: _ctx.getIllustrationsPath('16.png'),
      "button-text": "Create API Key",
      "modal-id": "kt_modal_create_api_key"
    }, null, 8, ["image"]),
    _createVNode(_component_KTCreateAPIKeyModal)
  ], 64))
}