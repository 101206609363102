import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_create_api_key",
  ref: "createAPIKeyModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_8 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_9 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6" }
const _hoisted_10 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }
const _hoisted_11 = { class: "mb-5 fv-row" }
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }
const _hoisted_14 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_18 = { class: "fv-plugins-message-container" }
const _hoisted_19 = { class: "fv-help-block" }
const _hoisted_20 = { class: "mb-10" }
const _hoisted_21 = { class: "fv-row" }
const _hoisted_22 = { class: "fv-plugins-message-container" }
const _hoisted_23 = { class: "fv-help-block" }
const _hoisted_24 = { class: "modal-footer flex-center" }
const _hoisted_25 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Create API Key", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createVNode(_component_Form, {
          id: "kt_modal_create_api_key_form",
          class: "form",
          onSubmit: _ctx.submit,
          "validation-schema": _ctx.validationSchema
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "d-flex flex-stack flex-grow-1" }, [
                    _createElementVNode("div", { class: "fw-bold" }, [
                      _createElementVNode("h4", { class: "text-gray-800 fw-bolder" }, "Please Note!"),
                      _createElementVNode("div", { class: "fs-6 text-gray-600" }, [
                        _createTextVNode(" Adding new API key may afftect to your "),
                        _createElementVNode("a", { href: "#" }, "Affiliate Income")
                      ])
                    ])
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "API Name", -1)),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-solid",
                    placeholder: "Your API Name",
                    name: "apiName",
                    modelValue: _ctx.apiData.apiName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apiData.apiName) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_ErrorMessage, { name: "apiName" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Short Description", -1)),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-solid",
                    rows: "3",
                    name: "shortDescription",
                    placeholder: "Describe your API",
                    modelValue: _ctx.apiData.shortDescription,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.apiData.shortDescription) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_ErrorMessage, { name: "shortDescription" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Category", -1)),
                  _createVNode(_component_Field, {
                    name: "category",
                    "data-control": "select2",
                    "data-hide-search": "true",
                    "data-placeholder": "Select a Category...",
                    class: "form-select form-select-solid",
                    as: "select",
                    modelValue: _ctx.apiData.category,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.apiData.category) = $event))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("option", { value: "" }, "Select a Category...", -1),
                      _createElementVNode("option", { value: "1" }, "CRM", -1),
                      _createElementVNode("option", { value: "2" }, "Project Alice", -1),
                      _createElementVNode("option", { value: "3" }, "Keenthemes", -1),
                      _createElementVNode("option", { value: "4" }, "General", -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_ErrorMessage, { name: "category" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "mb-3" }, [
                    _createElementVNode("label", { class: "d-flex align-items-center fs-5 fw-bold" }, [
                      _createElementVNode("span", { class: "required" }, "Specify Your API Method"),
                      _createElementVNode("i", {
                        class: "fas fa-exclamation-circle ms-2 fs-7",
                        "data-bs-toggle": "tooltip",
                        title: "Your billing numbers will be calculated based on your API method"
                      })
                    ]),
                    _createElementVNode("div", { class: "fs-7 fw-bold text-gray-400" }, " If you need more info, please check budget planning ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_Field, {
                      name: "apiMethod",
                      "data-control": "select2",
                      "data-hide-search": "true",
                      "data-placeholder": "Select a Category...",
                      class: "form-select form-select-solid",
                      as: "select",
                      modelValue: _ctx.apiData.apiMethod,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.apiData.apiMethod) = $event))
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createElementVNode("option", { value: "" }, "Select a API method...", -1),
                        _createElementVNode("option", { value: "1" }, "Open API", -1),
                        _createElementVNode("option", { value: "2" }, "SQL Call", -1),
                        _createElementVNode("option", { value: "3" }, "UI/UX", -1),
                        _createElementVNode("option", { value: "4" }, "Docs", -1)
                      ])),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_ErrorMessage, { name: "apiMethod" })
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _cache[13] || (_cache[13] = _createElementVNode("button", {
                type: "reset",
                id: "kt_modal_create_api_key_cancel",
                class: "btn btn-white me-3"
              }, " Discard ", -1)),
              _createElementVNode("button", _hoisted_25, _cache[12] || (_cache[12] = [
                _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1),
                _createElementVNode("span", { class: "indicator-progress" }, [
                  _createTextVNode(" Please wait... "),
                  _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                ], -1)
              ]), 512)
            ])
          ]),
          _: 1
        }, 8, ["onSubmit", "validation-schema"])
      ])
    ])
  ], 512))
}